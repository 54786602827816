(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.editorSkinsData = f()}})(function(){var define,module,exports;
/* Autogenerated file. Do not modify */
'use strict';
var skins = {};
 skins['skins.viewer.displayers.BlogSlideshowDisplayer'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ],
        [
          "div",
          "panel",
          [
            "_pnl"
          ],
          {},
          [
            "h3",
            "title",
            [],
            {}
          ],
          [
            "p",
            "description",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "brw": "MARGIN_SIZE",
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trns": "TRANSITION",
    "fntt": "FONT",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "s": "SIZE",
    "imageWrapperBottom": "MARGIN_SIZE",
    "imageWrapperTop": "MARGIN_SIZE",
    "imageWrapperRight": "MARGIN_SIZE",
    "imageWrapperLeft": "MARGIN_SIZE"
  },
  "paramsDefaults": {
    "brw": "0",
    "rd": "0",
    "bg": "rgba(0, 0, 0, 0.5)",
    "trns": "opacity 0.5s ease 0s",
    "fntt": "font_6",
    "txt": "#ffffff",
    "fntds": "font_8",
    "s": [
      "brw"
    ],
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "paramsMutators": {
    "s": {
      "type": "decrease",
      "value": 1,
      "param": "brw"
    }
  },
  "css": {
    "%imageWrapper": "position:absolute;",
    "%zoom": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];",
    "%image": "[rd]",
    "%panel": "[rd]  background-color:[bg];[trns]  opacity:0;border-top-left-radius:0 !important;border-top-right-radius:0 !important;bottom:[s];left:[brw];min-height:18px;padding:10px 10px 10px 10px;position:absolute;right:[brw];",
    "%title": "[fntt]  color:[txt];",
    "%description": "[fntds]  color:[txt];white-space:pre-line;",
    "%link": "[fntds]  color:[txt];",
    "%[data-state~=\"link\"] %link": "[fntds]  color:[txt];cursor:pointer;text-decoration:underline;",
    "%[data-state~=\"showPanel\"] %panel": "opacity:1;",
    "%[data-state~=\"notShowPanel\"] %panel": "opacity:0;",
    "%[data-state~=\"transOut\"] %panel": "opacity:0;",
    "%[data-state~=\"transIn\"] %panel": "opacity:0;"
  }
}
 skins['skins.viewer.displayers.SlideCleanAndSimple2Displayer'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ],
        [
          "div",
          "panel",
          [
            "_pnl"
          ],
          {},
          [
            "h3",
            "title",
            [],
            {}
          ],
          [
            "p",
            "description",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "brw": "MARGIN_SIZE",
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trns": "TRANSITION",
    "fntt": "FONT",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "s": "SIZE"
  },
  "paramsDefaults": {
    "brw": "0",
    "rd": "0",
    "bg": "color_15",
    "trns": "opacity 0.5s ease 0s",
    "fntt": "font_6",
    "txt": "color_11",
    "fntds": "font_8",
    "s": [
      "brw"
    ]
  },
  "paramsMutators": {
    "s": {
      "type": "decrease",
      "value": 1,
      "param": "brw"
    }
  },
  "css": {
    "%zoom": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];",
    "%image": "[rd]",
    "%panel": "[rd]  background-color:[bg];[trns]  opacity:0;position:absolute;right:[brw];left:[brw];bottom:[s];padding:10px 100px 10px 10px;min-height:18px;border-top-left-radius:0 !important;border-top-right-radius:0 !important;",
    "%title": "[fntt]  color:[txt];",
    "%description": "[fntds]  color:[txt];white-space:pre-line;",
    "%link": "[fntds]  color:[txt];",
    "%[data-state~=\"transOut\"] %panel": "opacity:0;",
    "%[data-state~=\"transIn\"] %panel": "opacity:0;",
    "%[data-state~=\"link\"] %link": "text-decoration:underline;cursor:pointer;[fntds]  color:[txt];"
  }
}
 skins['skins.viewer.displayers.SlideCleanAndSimpleDisplayer'] = {
  "react": [
    [
      "div",
      "imageWrapper",
      [],
      {},
      [
        "a",
        "link",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ],
        [
          "div",
          "panel",
          [
            "_pnl"
          ],
          {},
          [
            "h3",
            "title",
            [],
            {}
          ],
          [
            "p",
            "description",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "brw": "MARGIN_SIZE",
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trns": "TRANSITION",
    "fntt": "FONT",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "s": "SIZE",
    "imageWrapperBottom": "MARGIN_SIZE",
    "imageWrapperTop": "MARGIN_SIZE",
    "imageWrapperRight": "MARGIN_SIZE",
    "imageWrapperLeft": "MARGIN_SIZE"
  },
  "paramsDefaults": {
    "brw": "0",
    "rd": "0",
    "bg": "color_15",
    "trns": "opacity 0.5s ease 0s",
    "fntt": "font_6",
    "txt": "color_11",
    "fntds": "font_8",
    "s": [
      "brw"
    ],
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "paramsMutators": {
    "s": {
      "type": "decrease",
      "value": 1,
      "param": "brw"
    }
  },
  "css": {
    "%imageWrapper": "position:absolute;",
    "%zoom": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];",
    "%image": "[rd]",
    "%panel": "[rd]  background-color:[bg];[trns]  opacity:0;border-top-left-radius:0 !important;border-top-right-radius:0 !important;bottom:[s];left:[brw];min-height:18px;padding:10px 100px 10px 10px;position:absolute;right:[brw];",
    "%title": "[fntt]  color:[txt];",
    "%description": "[fntds]  color:[txt];white-space:pre-line;",
    "%link": "[fntds]  color:[txt];",
    "%[data-state~=\"link\"] %link": "[fntds]  color:[txt];cursor:pointer;text-decoration:underline;",
    "%[data-state~=\"showPanel\"] %panel": "opacity:1;",
    "%[data-state~=\"notShowPanel\"] %panel": "opacity:0;",
    "%[data-state~=\"transOut\"] %panel": "opacity:0;",
    "%[data-state~=\"transIn\"] %panel": "opacity:0;"
  }
}
 skins['skins.viewer.gallery.BlogSlideShow'] = {
  "react": [
    [
      "div",
      null,
      [
        "_border"
      ],
      {}
    ],
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_buttons"
      ],
      {},
      [
        "div",
        "buttonPrev",
        [
          "_btn"
        ],
        {},
        [
          "div",
          null,
          [
            "_arrowLeft",
            "_arrow"
          ],
          {}
        ]
      ],
      [
        "div",
        "buttonNext",
        [
          "_btn"
        ],
        {},
        [
          "div",
          null,
          [
            "_arrowRight",
            "_arrow"
          ],
          {}
        ]
      ]
    ],
    [
      "div",
      null,
      [
        "_helpers"
      ],
      {},
      [
        "div",
        "autoplay",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ],
      [
        "div",
        "counter",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "skins.viewer.displayers.BlogSlideshowDisplayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "trns": "TRANSITION",
    "abgh": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "txt": "BORDER_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "none",
    "brd": "rgba(0, 0, 0, 0)",
    "brw": "0",
    "trns": "opacity 0.5s ease 0s",
    "abgh": "rgba(0, 0, 0, 0.5)",
    "tdr": "BASE_THEME_DIRECTORY",
    "txt": "color_11"
  },
  "css": {
    "%": "-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-drag:none;-ms-user-drag:none;user-drag:none;-webkit-user-modify:ready-only;-moz-user-modify:ready-only;-ms-user-modify:ready-only;user-modify:ready-only;[rd]  [shd]  overflow:hidden;background:[brd];",
    "%_border": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:[brw] solid [brd];pointer-events:none;background:none !important;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  overflow:hidden;-webkit-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0);-webkit-mask-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);",
    "%_btn": "[trns]  background:[abgh];opacity:0.7;position:absolute;top:0;bottom:0;margin:auto;width:32px;height:134px;cursor:pointer;",
    "%_arrow": "background:url([tdr]tiny_arrows.png) no-repeat;position:absolute;top:0;bottom:0;margin:auto;width:8px;height:16px;",
    "%[data-state~=\"mobile\"] %_btn": "opacity:1;",
    "%buttonPrev": "left:0;",
    "%buttonNext": "right:0;",
    "%_arrowLeft": "background-position:0 0;left:50%;margin-left:-4px;",
    "%_arrowRight": "background-position:100% 0;right:50%;margin-right:-4px;",
    "%_helpers": "[trns]  opacity:0;position:absolute;right:[brw];padding:10px 10px 10px 0;width:60px;text-align:right;",
    "%counter": "display:inline-block;color:[txt];font-family:arial, helvetica,sans-serif;font-size:12px;padding-left:5px;",
    "%autoplay": "display:inline-block;padding-left:10px;width:20px;min-height:10px;position:relative;text-align:center;",
    "%autoplay > span": "display:inline-block;position:relative;z-index:0;",
    "%[data-state~=\"autoplayOff\"] %autoplay > span": "border:5px solid transparent;border-left:5px solid [txt];width:0;height:0;",
    "%[data-state~=\"autoplayOn\"] %autoplay > span": "border-left:2px solid [txt];border-right:2px solid [txt];height:10px;width:1px;margin-right:5px;",
    "%_buttons": "opacity:0;[trns]",
    "%[data-state~=\"mobile\"] %_buttons": "opacity:1;",
    "%:hover > %_buttons": "opacity:1;background:[abgh];[trns]",
    "%:hover > %_helpers": "opacity:1;",
    "%:hover %_pnl": "opacity:1;",
    "%[data-state~=\"showTextPanel\"] %_pnl": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_buttons": "background:[abgh];[trns]",
    "%[data-state~=\"touchRollOver\"] %_helpers": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_pnl": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_btn": "background:[abgh];",
    "%[data-state~=\"showButtons\"] %_buttons": "opacity:1;"
  }
}
 skins['skins.viewer.gallery.SlideShowCleanAndSimple'] = {
  "react": [
    [
      "div",
      null,
      [
        "_border"
      ],
      {}
    ],
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_buttons"
      ],
      {},
      [
        "div",
        "buttonPrev",
        [
          "_btn"
        ],
        {},
        [
          "div",
          null,
          [
            "_arrowLeft",
            "_arrow"
          ],
          {}
        ]
      ],
      [
        "div",
        "buttonNext",
        [
          "_btn"
        ],
        {},
        [
          "div",
          null,
          [
            "_arrowRight",
            "_arrow"
          ],
          {}
        ]
      ]
    ],
    [
      "div",
      null,
      [
        "_helpers"
      ],
      {},
      [
        "div",
        "autoplay",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ],
      [
        "div",
        "counter",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "skins.viewer.displayers.SlideCleanAndSimpleDisplayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "trns": "TRANSITION",
    "abg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "txt": "BORDER_COLOR",
    "abgh": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "brd": "color_15",
    "brw": "0",
    "trns": "opacity 0.5s ease 0s",
    "abg": "color_14",
    "tdr": "BASE_THEME_DIRECTORY",
    "txt": "color_11",
    "abgh": "color_15"
  },
  "css": {
    "%": "-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-drag:none;-ms-user-drag:none;user-drag:none;-webkit-user-modify:ready-only;-moz-user-modify:ready-only;-ms-user-modify:ready-only;user-modify:ready-only;[rd]  [shd]  overflow:hidden;background:[brd];",
    "%_border": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:[brw] solid [brd];pointer-events:none;background:none !important;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  overflow:hidden;-webkit-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0);-webkit-mask-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);",
    "%_btn": "[trns]  position:absolute;top:0;bottom:0;margin:auto;width:32px;height:134px;background:[abg];cursor:pointer;",
    "%_arrow": "background:url([tdr]tiny_arrows.png) no-repeat;position:absolute;top:0;bottom:0;margin:auto;width:8px;height:16px;",
    "%[data-state~=\"mobile\"] %_btn": "opacity:1;",
    "%buttonPrev": "left:0;",
    "%buttonNext": "right:0;",
    "%_arrowLeft": "background-position:0 0;left:50%;margin-left:-4px;",
    "%_arrowRight": "background-position:100% 0;right:50%;margin-right:-4px;",
    "%_helpers": "[trns]  opacity:0;position:absolute;right:[brw];padding:10px 10px 10px 0;width:60px;text-align:right;",
    "%counter": "display:inline-block;color:[txt];font-family:arial, helvetica,sans-serif;font-size:12px;padding-left:5px;",
    "%autoplay": "display:inline-block;padding-left:10px;width:20px;min-height:10px;position:relative;text-align:center;",
    "%autoplay > span": "display:inline-block;position:relative;z-index:0;",
    "%[data-state~=\"autoplayOff\"] %autoplay > span": "border:5px solid transparent;border-left:5px solid [txt];width:0;height:0;",
    "%[data-state~=\"autoplayOn\"] %autoplay > span": "border-left:2px solid [txt];border-right:2px solid [txt];height:10px;width:1px;margin-right:5px;",
    "%_buttons": "[trns]",
    "%[data-state~=\"mobile\"] %_buttons": "opacity:1;",
    "%:hover > %_buttons": "background:[abgh];[trns]",
    "%:hover > %_helpers": "opacity:1;",
    "%:hover %_pnl": "opacity:1;",
    "%[data-state~=\"showTextPanel\"] %_pnl": "opacity:1;",
    "%_btn:hover": "background:[abgh];[trns]",
    "%[data-state~=\"touchRollOver\"] %_buttons": "background:[abgh];[trns]",
    "%[data-state~=\"touchRollOver\"] %_helpers": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_pnl": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_btn": "background:[abgh];",
    "%[data-state~=\"showButtons\"] %_buttons": "opacity:1;",
    "%[data-text-alignment=\"right\"] %imageItempanel": "padding-right:10px;padding-left:100px;",
    "%[data-text-alignment=\"right\"] %_helpers": "left:0px;right:initial;text-align:left;",
    "%[data-text-alignment=\"right\"][data-state~=\"hidePlayButton\"] %autoplay": "display:none;"
  }
}
 skins['skins.viewer.gallery.SlideShowCleanAndSimple2'] = {
  "react": [
    [
      "div",
      null,
      [
        "_border"
      ],
      {}
    ],
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_buttons"
      ],
      {},
      [
        "div",
        "buttonPrev",
        [
          "_btn"
        ],
        {},
        [
          "div",
          null,
          [
            "_arrowLeft",
            "_arrow"
          ],
          {}
        ]
      ],
      [
        "div",
        "buttonNext",
        [
          "_btn"
        ],
        {},
        [
          "div",
          null,
          [
            "_arrowRight",
            "_arrow"
          ],
          {}
        ]
      ]
    ],
    [
      "div",
      null,
      [
        "_helpers"
      ],
      {},
      [
        "div",
        "autoplay",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ],
      [
        "div",
        "counter",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "skins.viewer.displayers.SlideCleanAndSimple2Displayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "trns": "TRANSITION",
    "abg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "txt": "BORDER_COLOR",
    "abgh": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "brd": "color_15",
    "brw": "0",
    "trns": "opacity 0.5s ease 0s",
    "abg": "color_14",
    "tdr": "BASE_THEME_DIRECTORY",
    "txt": "color_11",
    "abgh": "color_15"
  },
  "css": {
    "%": "[rd]  [shd]  overflow:hidden;background:[brd];",
    "%_border": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:[brw] solid [brd];pointer-events:none;background:none !important;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  overflow:hidden;-webkit-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0);",
    "%_btn": "[trns]  position:absolute;top:0;bottom:0;margin:auto;width:40px;height:40px;background:[abg];cursor:pointer;",
    "%_arrow": "background:url([tdr]tiny_arrows.png) no-repeat;position:absolute;top:0;bottom:0;margin:auto;width:8px;height:16px;",
    "%[data-state~=\"mobile\"] %_btn": "opacity:1;",
    "%buttonPrev": "left:20px;",
    "%buttonNext": "right:20px;",
    "%_arrowLeft": "background-position:0 0;left:50%;margin-left:-4px;",
    "%_arrowRight": "background-position:100% 0;right:50%;margin-right:-4px;",
    "%_helpers": "[trns]  opacity:0;position:absolute;right:[brw];padding:10px 10px 10px 0;width:60px;text-align:right;",
    "%counter": "display:inline-block;color:[txt];font-family:arial, helvetica,sans-serif;font-size:12px;padding-left:5px;",
    "%autoplay": "display:inline-block;padding-left:10px;width:20px;min-height:10px;position:relative;text-align:center;",
    "%autoplay > span": "display:inline-block;position:relative;z-index:0;",
    "%[data-state~=\"autoplayOff\"] %autoplay > span": "border:5px solid transparent;border-left:5px solid [txt];width:0;height:0;",
    "%[data-state~=\"autoplayOn\"] %autoplay > span": "border-left:2px solid [txt];border-right:2px solid [txt];height:10px;width:1px;margin-right:5px;",
    "%_buttons": "[trns]",
    "%[data-state~=\"mobile\"] %_buttons": "opacity:1;",
    "%:hover > %_buttons": "background:[abgh];[trns]",
    "%:hover > %_helpers": "opacity:1;",
    "%:hover %_pnl": "opacity:1;",
    "%[data-state~=\"showTextPanel\"] %_pnl": "opacity:1;",
    "%_btn:hover": "background:[abgh];[trns]",
    "%[data-state~=\"touchRollOver\"] %_buttons": "background:[abgh];[trns]",
    "%[data-state~=\"touchRollOver\"] %_helpers": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_pnl": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_btn": "background:[abgh];",
    "%[data-state~=\"showButtons\"] %_buttons": "opacity:1;"
  }
}
 skins['skins.viewer.gallery.SlideShowIron'] = {
  "react": [
    [
      "div",
      null,
      [
        "_border"
      ],
      {}
    ],
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_buttons"
      ],
      {},
      [
        "div",
        "buttonPrev",
        [
          "_btn"
        ],
        {},
        [
          "div",
          null,
          [
            "_arrowLeft"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_screwTL",
            "_screw"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_screwBL",
            "_screw"
          ],
          {}
        ]
      ],
      [
        "div",
        "buttonNext",
        [
          "_btn"
        ],
        {},
        [
          "div",
          null,
          [
            "_arrowRight"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_screwTR",
            "_screw"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_screwBR",
            "_screw"
          ],
          {}
        ]
      ]
    ],
    [
      "div",
      null,
      [
        "_helpers"
      ],
      {},
      [
        "div",
        "autoplay",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ],
      [
        "div",
        "counter",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "skins.viewer.displayers.SlideIronDisplayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brd": "BG_COLOR_ALPHA",
    "ishdd": "BOX_SHADOW",
    "tdr": "URL",
    "abg": "BG_COLOR_ALPHA",
    "txt": "BORDER_COLOR"
  },
  "paramsDefaults": {
    "rd": "2px",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "brd": "color_15",
    "ishdd": "rgba(255, 255, 255, 0.4) 0px 0px 6px 0px inset, rgba(255, 255, 255, 0.701961) 0px 1px 0px 0px inset, rgba(255, 255, 255, 0.2) 0px 0px 5px 0px inset, rgba(0, 0, 0, 0.498039) 0px 1px 3px 0px",
    "tdr": "BASE_THEME_DIRECTORY",
    "abg": "color_11",
    "txt": "color_11"
  },
  "css": {
    "%": "-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-drag:none;-ms-user-drag:none;user-drag:none;-webkit-user-modify:ready-only;-moz-user-modify:ready-only;-ms-user-modify:ready-only;user-modify:ready-only;[rd]  [shd]  background:[brd];",
    "%_border": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  pointer-events:none;background:none !important;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  overflow:hidden;-webkit-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0);",
    "%[data-state~=\"mobile\"] %_btn": "opacity:1;",
    "%buttonPrev": "left:0;[ishdd]  width:44px;height:43px;border-radius:2px;",
    "%buttonNext": "right:0;[ishdd]  width:44px;height:43px;border-radius:2px;",
    "%_imgGlow": "[rd]  position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_btn": "position:absolute;top:35%;margin-top:-22.5px;width:45px;background:url([tdr]ironpatern.png) no-repeat;cursor:pointer;background-color:[abg];",
    "%_arrowLeft": "position:relative;top:27%;left:38%;width:0;height:0;border-top:10px solid rgba(0, 0, 0, 0);border-bottom:10px solid rgba(0, 0, 0, 0);border-right:12px solid #202020;",
    "%_arrowRight": "position:relative;top:27%;left:36%;width:0;height:0;border-top:10px solid rgba(0, 0, 0, 0);border-bottom:10px solid rgba(0, 0, 0, 0);border-left:12px solid #202020;",
    "%_screw": "display:inline-block;background-image:url([tdr]skrew.png);background-repeat:no-repeat;width:15px;height:15px;",
    "%_screwTL": "position:absolute;top:2px;left:2px;",
    "%_screwTR": "position:absolute;top:2px;right:2px;",
    "%_screwBL": "position:absolute;bottom:2px;left:2px;",
    "%_screwBR": "position:absolute;bottom:2px;right:2px;",
    "%_helpers": "position:absolute;right:15px !important;bottom:90px !important;padding:0 10px 10px 0;width:60px;text-align:right;",
    "%counter": "display:inline-block;color:[txt];font-family:arial, helvetica,sans-serif;font-size:12px;padding-left:5px;",
    "%autoplay": "display:inline-block;padding-left:10px;width:20px;min-height:10px;position:relative;text-align:center;",
    "%autoplay > span": "display:inline-block;position:relative;z-index:0;",
    "%[data-state~=\"autoplayOff\"] %autoplay > span": "border:5px solid transparent;border-left:5px solid [txt];width:0;height:0;",
    "%[data-state~=\"autoplayOn\"] %autoplay > span": "border-left:2px solid [txt];border-right:2px solid [txt];height:10px;width:1px;margin-right:5px;",
    "%[data-state~=\"mobile\"] %_buttons": "opacity:1;",
    "%:hover %_pnl": "opacity:1;",
    "%[data-state~=\"showTextPanel\"] %_pnl": "opacity:1;",
    "%_btn:hover": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_pnl": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_btn": "opacity:1;",
    "%[data-state~=\"showButtons\"] %_buttons": "opacity:1;",
    "%[data-text-alignment=\"right\"] %_helpers": "left:15px !important;bottom:90px !important;text-align:left;",
    "%[data-text-alignment=\"right\"][data-state~=\"hidePlayButton\"] %autoplay": "display:none;"
  }
}
 skins['skins.viewer.gallery.SlideShowScotchTape'] = {
  "react": [
    [
      "div",
      null,
      [
        "_shadowTL"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadowTR"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadowBL"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_shadowBR"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_border"
      ],
      {}
    ],
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_buttons"
      ],
      {},
      [
        "div",
        "buttonPrev",
        [
          "_btn"
        ],
        {}
      ],
      [
        "div",
        "buttonNext",
        [
          "_btn"
        ],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_helpers"
      ],
      {},
      [
        "div",
        "autoplay",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ],
      [
        "div",
        "counter",
        [],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_ScotchL"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_ScotchR"
      ],
      {}
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "skins.viewer.displayers.SlideScotchTapeDisplayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "trns": "TRANSITION",
    "tdr": "URL",
    "txt": "BORDER_COLOR"
  },
  "paramsDefaults": {
    "shd": "0 1px 0 rgba(0, 0, 0, 0.1)",
    "brd": "color_11",
    "brw": "0",
    "trns": "opacity 0.5s ease 0s",
    "tdr": "BASE_THEME_DIRECTORY",
    "txt": "color_11"
  },
  "css": {
    "%": "-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-drag:none;-ms-user-drag:none;user-drag:none;-webkit-user-modify:ready-only;-moz-user-modify:ready-only;-ms-user-modify:ready-only;user-modify:ready-only;[shd]  background:[brd];overflow:visible !important;",
    "%_border": "position:absolute;top:0;right:0;bottom:0;left:0;border:[brw] solid [brd];pointer-events:none;background:none !important;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;-webkit-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0);",
    "%_btn": "[trns]  opacity:0.4;height:70px;background:url([tdr]arrows_white_new3.png) no-repeat;width:45px;position:absolute;top:50%;margin-top:-35px;cursor:pointer;",
    "%[data-state~=\"mobile\"] %_btn": "opacity:1;",
    "%buttonPrev": "left:20px;background-position:0 0;",
    "%buttonNext": "right:20px;background-position:100% 0;",
    "%_helpers": "[trns]  opacity:0;position:absolute;right:[brw];bottom:[brw];padding:0 10px 10px 0;width:60px;text-align:right;",
    "%counter": "display:inline-block;color:[txt];font-family:arial, helvetica,sans-serif;font-size:12px;padding-left:5px;",
    "%autoplay": "display:inline-block;padding-left:10px;width:20px;min-height:10px;position:relative;text-align:center;",
    "%autoplay > span": "display:inline-block;position:relative;z-index:0;",
    "%[data-state~=\"autoplayOff\"] %autoplay > span": "border:5px solid transparent;border-left:5px solid [txt];width:0;height:0;",
    "%[data-state~=\"autoplayOn\"] %autoplay > span": "border-left:2px solid [txt];border-right:2px solid [txt];height:10px;width:1px;margin-right:5px;",
    "%_buttons": "[trns]  opacity:0;",
    "%[data-state~=\"mobile\"] %_buttons": "opacity:1;",
    "%:hover > %_buttons": "opacity:1;",
    "%:hover > %_helpers": "opacity:1;",
    "%:hover %_pnl": "opacity:1;",
    "%[data-state~=\"showTextPanel\"] %_pnl": "opacity:1;",
    "%_btn:hover": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_buttons": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_helpers": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_pnl": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_btn": "opacity:1;",
    "%[data-state~=\"showButtons\"] %_buttons": "opacity:1;",
    "%_shadowTL": "position:absolute;display:inline-block;background-image:url([tdr]shadow_top_left.png);background-repeat:no-repeat;width:14px;height:194px;top:-2px;left:-10px;",
    "%_shadowTR": "position:absolute;display:inline-block;background-image:url([tdr]shadow_top_right.png);background-repeat:no-repeat;width:14px;height:194px;top:-2px;right:-10px;",
    "%_shadowBL": "position:absolute;display:inline-block;background-image:url([tdr]shadow_bottom_left.png);background-repeat:no-repeat;width:14px;height:194px;bottom:-2px;left:-10px;",
    "%_shadowBR": "position:absolute;display:inline-block;background-image:url([tdr]shadow_bottom_right.png);background-repeat:no-repeat;width:14px;height:194px;bottom:-2px;right:-10px;",
    "%_ScotchL": "position:absolute;display:inline-block;background-image:url([tdr]scotchl.png);background-repeat:no-repeat;width:85px;height:86px;top:-20px;left:-30px;",
    "%_ScotchR": "position:absolute;display:inline-block;background-image:url([tdr]scotchr.png);background-repeat:no-repeat;width:85px;height:86px;top:-20px;right:-30px;",
    "%[data-text-alignment=\"right\"] %imageItempanel": "padding-right:10px;padding-left:100px;",
    "%[data-text-alignment=\"right\"] %_helpers": "left:0px;right:initial;text-align:left;",
    "%[data-text-alignment=\"right\"][data-state~=\"hidePlayButton\"] %autoplay": "display:none;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.FrameDisplayer'] = {
  "react": [
    [
      "div",
      "imageWrapper",
      [],
      {},
      [
        "div",
        "zoom",
        [],
        {},
        [
          "div",
          "image",
          [],
          {}
        ]
      ]
    ],
    [
      "div",
      "panel",
      [
        "_panel"
      ],
      {},
      [
        "a",
        "link",
        [],
        {},
        [
          "div",
          "title",
          [],
          {}
        ],
        [
          "div",
          "description",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "heightDiff": 60,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "brd": "BORDER_COLOR_ALPHA",
    "bgc": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "brd": "color_15",
    "bgc": "color_11"
  },
  "css": {
    "%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;border:5px solid [brd];",
    "%panel": "background-color:[bgc];height:40px;padding:10px;color:#fff;position:absolute;bottom:0;left:0;right:0;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.RoundFrameDisplayer'] = {
  "react": [
    [
      "div",
      "imageWrapper",
      [],
      {},
      [
        "div",
        "zoom",
        [],
        {},
        [
          "div",
          "image",
          [],
          {}
        ]
      ]
    ],
    [
      "div",
      "panel",
      [],
      {}
    ],
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "title",
        [],
        {}
      ],
      [
        "div",
        "description",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 60,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "css": {
    "%link": "display:block;cursor:pointer;margin-right:140px;",
    "%imageWrapper": "margin:0 0 10px 0;",
    "%zoom": "height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.SlideDisplayer'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {}
    ],
    [
      "div",
      "imageWrapper",
      [],
      {},
      [
        "div",
        "image",
        [],
        {}
      ]
    ],
    [
      "div",
      "panel",
      [
        "_panel"
      ],
      {},
      [
        "div",
        "title",
        [],
        {}
      ],
      [
        "div",
        "description",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "trns": "TRANSITION",
    "bgc": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "trns": "opacity 0.5s ease 0s",
    "bgc": "color_11"
  },
  "css": {
    "%": "[trns]",
    "%[data-state~=\"transOut\"]": "opacity:0;",
    "%[data-state~=\"transIn\"]": "opacity:0;",
    "%panel": "background-color:[bgc];height:40px;padding:10px;color:#fff;position:absolute;bottom:50px;left:0;right:100px;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.FrameShowGallery'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      "buttonPrev",
      [
        "_btn"
      ],
      {},
      [
        "div",
        null,
        [
          "_arr",
          "_fl"
        ],
        {}
      ]
    ],
    [
      "div",
      "buttonNext",
      [
        "_btn"
      ],
      {},
      [
        "div",
        null,
        [
          "_arr",
          "_fr"
        ],
        {}
      ]
    ],
    [
      "div",
      "counter",
      [
        "_helpers"
      ],
      {}
    ],
    [
      "div",
      "autoplay",
      [
        "_helpers"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_xxx"
      ],
      {}
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.FrameDisplayer"
    }
  },
  "params": {
    "tdr": "URL",
    "shd": "BOX_SHADOW",
    "bgc": "BG_COLOR_ALPHA",
    "rd1": "BORDER_RADIUS",
    "rd2": "BORDER_RADIUS",
    "clr": "BORDER_COLOR"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "shd": "0 1px 2px rgba(0, 0, 0, 0.6)",
    "bgc": "color_1",
    "rd1": "0 5px 5px 0",
    "rd2": "5px 0 0 5px",
    "clr": "color_2"
  },
  "css": {
    "%_xxx": "position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;",
    "%": "[shd]",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_btn": "background-color:[bgc];padding:10px;text-align:center;position:absolute;top:40%;cursor:pointer;",
    "%buttonPrev": "left:0;[rd1]",
    "%buttonNext": "right:0;[rd2]",
    "%_arr": "height:0;width:0;border:10px solid transparent;",
    "%_fr": "border-left:10px solid [clr];",
    "%_fl": "border-right:10px solid [clr];",
    "%_helpers": "position:absolute;bottom:30px;padding:10px;color:[clr];",
    "%counter": "left:100px;right:0;",
    "%autoplay": "left:0;width:80px;text-align:center;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.RibbonsSlideShow'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      "buttonPrev",
      [
        "_btn"
      ],
      {},
      [
        "div",
        null,
        [],
        {}
      ]
    ],
    [
      "div",
      "buttonNext",
      [
        "_btn"
      ],
      {},
      [
        "div",
        null,
        [],
        {}
      ]
    ],
    [
      "div",
      "counter",
      [
        "_hlp"
      ],
      {}
    ],
    [
      "div",
      "autoplay",
      [
        "_hlp"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_xxx"
      ],
      {}
    ]
  ],
  "exports": {
    "widthDiff": 20,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.FrameDisplayer"
    }
  },
  "params": {
    "tdr": "URL",
    "bgc": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bgc1": "BG_COLOR_ALPHA",
    "clr": "COLOR"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "bgc": "color_1",
    "rd": "0",
    "shd": "0 1px 2px rgba(0, 0, 0, 0.6)",
    "bgc1": "color_2",
    "clr": [
      "bgc1"
    ]
  },
  "paramsMutators": {
    "clr": {
      "type": "saturation",
      "value": 0.4,
      "param": "bgc1"
    }
  },
  "css": {
    "%_xxx": "position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;",
    "%": "background-color:[bgc];[rd]  [shd]",
    "%:hover > %autoplay": "left:0;",
    "%:hover > %counter": "left:66px;",
    "%_btn": "background-color:[bgc1];[shd]  color:#fff;position:absolute;top:50%;margin-top:-20px;width:40px;height:40px;line-height:40px;text-align:center;",
    "%_btn div": "position:absolute;bottom:-10px;height:0;width:0;border:5px solid transparent;",
    "%buttonPrev div,%buttonNext div": "border-top:5px solid [clr];",
    "%buttonPrev": "left:0;",
    "%buttonPrev div": "border-right:5px solid [clr];left:0;",
    "%buttonNext": "right:0;",
    "%buttonNext div": "border-left:5px solid [clr];right:0;",
    "%_hlp": "padding:5px;position:absolute;left:-100px;bottom:10px;background-color:[bgc];-webkit-transition:left .5s;transition:left .5s;",
    "%itemsContainer": "[rd]  overflow:hidden;position:absolute;top:10px;right:10px;bottom:10px;left:10px;",
    "%[data-state~=\"autoplayOn\"] %autoplay,%[data-state~=\"autoplayOff\"] %autoplay": "display:block;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.RoundSlideShowGallery'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      "buttonPrev",
      [
        "_btn"
      ],
      {}
    ],
    [
      "div",
      "buttonNext",
      [
        "_btn"
      ],
      {}
    ],
    [
      "div",
      "counter",
      [
        "_hlp"
      ],
      {}
    ],
    [
      "div",
      "autoplay",
      [
        "_hlp"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_xxx"
      ],
      {}
    ]
  ],
  "exports": {
    "widthDiff": 20,
    "heightDiff": 20,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.RoundFrameDisplayer"
    }
  },
  "params": {
    "tdr": "URL",
    "bg": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "shd1": "BOX_SHADOW",
    "bge": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "bg": "color_1",
    "rd": "3px",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.6)",
    "shd1": "inset -3px -5px 15px rgba(0, 0, 0, 0.4)",
    "bge": "color_6"
  },
  "css": {
    "%_xxx": "position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;",
    "%": "background-color:[bg];[rd]  [shd]",
    "%_btn": "[rd]  [shd1]  background-color:[bge];background:url([tdr]arrows_thin.gif) no-repeat;position:absolute;bottom:10px;width:40px;height:40px;line-height:40px;text-align:center;",
    "%buttonPrev": "right:60px;background-position:10px 50%;",
    "%buttonNext": "right:10px;background-position:105% 50%;",
    "%autoplay": "position:absolute;right:120px;bottom:40px;",
    "%counter": "position:absolute;right:120px;bottom:20px;",
    "%itemsContainer": "overflow:hidden;position:absolute;left:10px;right:10px;top:10px;bottom:10px;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.SlideShowGalleryLiftedShadowSkin'] = {
  "react": [
    [
      "div",
      null,
      [
        "_border"
      ],
      {}
    ],
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_buttons"
      ],
      {},
      [
        "div",
        "buttonPrev",
        [
          "_btn"
        ],
        {}
      ],
      [
        "div",
        "buttonNext",
        [
          "_btn"
        ],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_helpers"
      ],
      {},
      [
        "div",
        "autoplay",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ],
      [
        "div",
        "counter",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.SlideShowDisplayerLiftedShadowSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "tdr": "URL",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "trns": "TRANSITION",
    "txt": "BORDER_COLOR"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "rd": "0",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "brd": "color_15",
    "brw": "0",
    "trns": "opacity 0.5s ease 0s",
    "txt": "color_11"
  },
  "css": {
    "%": "-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-drag:none;-ms-user-drag:none;user-drag:none;-webkit-user-modify:ready-only;-moz-user-modify:ready-only;-ms-user-modify:ready-only;user-modify:ready-only;[rd]  [shd]  background:[brd];overflow:visible !important;",
    "%:before": "content:\"\";position:absolute;top:100%;bottom:-30px;width:165px;left:-20px;background:url([tdr]liftedshadow_medium.png) no-repeat 0 0;",
    "%:after": "content:\"\";position:absolute;top:100%;bottom:-30px;width:165px;right:-20px;background:url([tdr]liftedshadow_medium.png) no-repeat 100% 0;",
    "%_border": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:[brw] solid [brd];pointer-events:none;background:none !important;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  overflow:hidden;",
    "%_btn": "[trns]  opacity:0.4;height:70px;background:url([tdr]arrows_white_new3.png) no-repeat;width:45px;position:absolute;top:50%;margin-top:-35px;cursor:pointer;",
    "%[data-state~=\"mobile\"] %_btn": "opacity:1;",
    "%buttonPrev": "left:20px;background-position:0 0;",
    "%buttonNext": "right:20px;background-position:100% 0;",
    "%_helpers": "[trns]  opacity:0;position:absolute;right:[brw];bottom:[brw];padding:0 10px 10px 0;width:60px;text-align:right;",
    "%[data-state~=\"mobile\"] %_helpers": "opacity:1;",
    "%counter": "display:inline-block;color:[txt];font-family:arial, helvetica,sans-serif;font-size:12px;padding-left:5px;",
    "%autoplay": "display:inline-block;padding-left:10px;width:20px;min-height:10px;position:relative;text-align:center;",
    "%autoplay > span": "display:inline-block;position:relative;z-index:0;",
    "%[data-state~=\"autoplayOff\"] %autoplay > span": "border:5px solid transparent;border-left:5px solid [txt];width:0;height:0;",
    "%[data-state~=\"autoplayOn\"] %autoplay > span": "border-left:2px solid [txt];border-right:2px solid [txt];height:10px;width:1px;margin-right:5px;",
    "%_buttons": "[trns]  opacity:0;",
    "%[data-state~=\"mobile\"] %_buttons": "opacity:1;",
    "%:hover > %_buttons": "opacity:1;",
    "%:hover > %_helpers": "opacity:1;",
    "%:hover %_pnl": "opacity:1;",
    "%[data-state~=\"showTextPanel\"] %_pnl": "opacity:1;",
    "%_btn:hover": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_buttons": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_helpers": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_pnl": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_btn": "opacity:1;",
    "%[data-state~=\"showButtons\"] %_buttons": "opacity:1;",
    "%[data-text-alignment=\"right\"] %imageItempanel": "padding-right:10px;padding-left:100px;",
    "%[data-text-alignment=\"right\"] %_helpers": "left:0px;right:initial;text-align:left;",
    "%[data-text-alignment=\"right\"][data-state~=\"hidePlayButton\"] %autoplay": "display:none;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.SlideShowGallerySimple'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      "buttonPrev",
      [
        "_btn"
      ],
      {},
      [
        "div",
        null,
        [
          "_arr",
          "_fl"
        ],
        {}
      ]
    ],
    [
      "div",
      "buttonNext",
      [
        "_btn"
      ],
      {},
      [
        "div",
        null,
        [
          "_arr",
          "_fr"
        ],
        {}
      ]
    ],
    [
      "div",
      "counter",
      [
        "_helpers"
      ],
      {}
    ],
    [
      "div",
      "autoplay",
      [
        "_helpers"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_xxx"
      ],
      {}
    ]
  ],
  "exports": {
    "heightDiff": 0,
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.SlideDisplayer",
      "styleGroup": "displayer"
    }
  },
  "params": {
    "tdr": "URL",
    "trans1": "TRANSITION",
    "trans2": "TRANSITION",
    "clr": "BORDER_COLOR",
    "bgc": "BG_COLOR"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "trans1": "left 0.7s ease 0s",
    "trans2": "right 0.7s ease 0s",
    "clr": "color_11",
    "bgc": "color_11"
  },
  "paramsMutators": {
    "bgc": {
      "type": "alpha",
      "value": 0.6
    }
  },
  "css": {
    "%_xxx": "position:absolute;top:0;right:0;bottom:0;left:0;background:url([tdr]net.png) center center repeat;",
    "%_btn": "background-color:[bgc];padding:10px;text-align:center;position:absolute;top:50%;margin-top:-15px;cursor:pointer;",
    "%buttonPrev": "left:-50px;[trans1]",
    "%buttonNext": "right:-50px;[trans2]",
    "%:hover %buttonPrev": "left:0;",
    "%:hover %buttonNext": "right:0;",
    "%[data-state~=\"touchRollOver\"] %buttonPrev": "left:0;",
    "%[data-state~=\"touchRollOver\"] %buttonNext": "right:0;",
    "%_arr": "height:0;width:0;border:5px solid transparent;",
    "%_fr": "border-left:5px solid [clr];",
    "%_fl": "border-right:5px solid [clr];",
    "%_helpers": "position:absolute;bottom:10px;background-color:[bgc];padding:10px;height:15px;color:[clr];",
    "%counter": "right:110px;",
    "%autoplay": "right:10px;width:80px;text-align:center;",
    "%[data-state~=\"showButtons\"] %buttonPrev": "left:0;",
    "%[data-state~=\"showButtons\"] %buttonNext": "right:0;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.SlideShowGallerySloopy'] = {
  "react": [
    [
      "div",
      null,
      [
        "_border"
      ],
      {}
    ],
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_helpers"
      ],
      {},
      [
        "div",
        "autoplay",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ],
      [
        "div",
        "buttonPrev",
        [],
        {},
        [
          "div",
          "buttonPrevArrow",
          [],
          {}
        ]
      ],
      [
        "div",
        "counter",
        [],
        {}
      ],
      [
        "div",
        "buttonNext",
        [],
        {},
        [
          "div",
          "buttonNextArrow",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.SlideShowSloopyDisplayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "tdr": "URL",
    "trns": "TRANSITION",
    "txt": "BORDER_COLOR",
    "bg": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "trns": "opacity 0.5s ease 0s",
    "txt": "color_11",
    "bg": "color_15"
  },
  "css": {
    "%": "-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-drag:none;-ms-user-drag:none;user-drag:none;-webkit-user-modify:ready-only;-moz-user-modify:ready-only;-ms-user-modify:ready-only;user-modify:ready-only;overflow:visible !important;",
    "%_border": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_border:before": "content:\"\";background-repeat:no-repeat;position:absolute;top:-8px;left:-8px;bottom:-4px;right:-4px;background:url([tdr]sloppyframe.png);background-position:0 0;",
    "%_border:after": "content:\"\";background-repeat:no-repeat;position:absolute;top:-4px;left:-4px;bottom:-8px;right:-8px;background:url([tdr]sloppyframe.png);background-position:100% 100%;",
    "%itemsContainer": "background-color:#000;position:absolute;top:0;right:0;bottom:0;left:0;overflow:hidden;",
    "%_helpers": "position:absolute;[trns]  opacity:0;bottom:10px;right:0;color:[txt];",
    "%[data-state~=\"mobile\"] %_helpers": "opacity:1;",
    "%:hover > %_helpers": "background-color:[bg];opacity:1;",
    "%:hover %_pnl": "background-color:[bg];opacity:1;",
    "%[data-state~=\"showTextPanel\"] %_pnl": "background-color:[bg];opacity:1;",
    "%_helpers > div": "float:left;font-family:arial, helvetica,sans-serif;font-size:12px;",
    "%autoplay": "width:10px;height:10px;line-height:10px;padding-top:2px;cursor:pointer;text-align:center;",
    "%autoplay span": "display:inline-block;",
    "%[data-state~=\"autoplayOff\"] span": "border:5px solid transparent;border-left:5px solid [txt];width:0;height:0;",
    "%[data-state~=\"autoplayOn\"] span": "border-left:2px solid [txt];border-right:2px solid [txt];width:1px;height:10px;margin-right:5px;",
    "%[data-state~=\"showButtons\"] %_helpers": "opacity:1;",
    "%buttonPrevArrow": "margin-top:4px;cursor:pointer;height:6px;width:6px;position:relative;margin-left:6px;border-style:solid;border-color:[txt];background:transparent;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);border-width:1px 0 0 1px;",
    "%buttonPrev,%buttonNext,%autoplay": "padding:15px;",
    "%counter": "padding-top:15px;",
    "%buttonNextArrow": "margin-top:4px;margin-right:8px;cursor:pointer;position:relative;height:6px;width:6px;border-style:solid;border-color:[txt];background:transparent;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);border-width:0 1px 1px 0;",
    "%[data-text-alignment=\"right\"] %_helpers": "left:0;right:initial;",
    "%[data-text-alignment=\"right\"][data-state~=\"hidePlayButton\"] %autoplay": "display:none;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.SlideShowPolaroid'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_btn"
      ],
      {},
      [
        "div",
        "buttonPrev",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ],
      [
        "div",
        "buttonNext",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ]
    ],
    [
      "div",
      null,
      [
        "_helpers"
      ],
      {},
      [
        "div",
        "autoplay",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ],
      [
        "div",
        "counter",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.SlideShowPolaroidDisplayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "trns": "TRANSITION",
    "txt": "BORDER_COLOR",
    "s": "SIZE"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "rd": "5px",
    "trns": "opacity 0.5s ease 0s",
    "txt": "color_15",
    "s": [
      "brw"
    ]
  },
  "paramsMutators": {
    "s": {
      "type": "decrease",
      "value": 15,
      "param": "brw"
    }
  },
  "css": {
    "%": "-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-drag:none;-ms-user-drag:none;user-drag:none;-webkit-user-modify:ready-only;-moz-user-modify:ready-only;-ms-user-modify:ready-only;user-modify:ready-only;background-color:[bg];[shd]  [rd]  margin-top:1px;",
    "%_btn div": "height:40px;line-height:45px;width:50px;background-color:[bg];[rd]  position:absolute;top:50%;margin-top:-30px;cursor:pointer;text-align:center;",
    "%buttonPrev": "[trns]  opacity:0;left:[s];[rd]  border-bottom-left-radius:0 !important;border-top-left-radius:0 !important;",
    "%[data-state~=\"mobile\"] %buttonPrev": "opacity:1;",
    "%buttonNext": "[trns]  opacity:0;right:[s];[rd]  border-bottom-right-radius:0 !important;border-top-right-radius:0 !important;",
    "%[data-state~=\"mobile\"] %buttonNext": "opacity:1;",
    "%_btn span": "display:inline-block;border:5px solid transparent;",
    "%buttonNext span": "border-left:10px solid [txt];margin-right:5px;",
    "%buttonPrev span": "border-right:10px solid [txt];margin-left:5px;",
    "%:hover %_btn div": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_btn div": "opacity:1;",
    "%_helpers": "position:absolute;bottom:45px;right:10px;text-align:right;color:[txt];",
    "%counter": "display:inline-block;padding-left:5px;font-family:arial, helvetica,sans-serif;font-size:12px;",
    "%autoplay": "display:inline-block;width:10px;height:10px;line-height:10px;padding:5px;cursor:pointer;text-align:center;",
    "%[data-state~=\"autoplayOff\"] %autoplay > span": "display:inline-block;border:5px solid transparent;border-left:5px solid [txt];width:0;height:0;",
    "%[data-state~=\"autoplayOn\"] %autoplay > span": "display:inline-block;border-left:2px solid [txt];border-right:2px solid [txt];height:10px;width:1px;margin-right:5px;",
    "%[data-state~=\"showButtons\"] %_btn div": "opacity:1;",
    "%[data-text-alignment=\"right\"] %imageItempanel": "right:10px;left:90px;",
    "%[data-text-alignment=\"right\"] %_helpers": "left:10px;right:initial;",
    "%[data-text-alignment=\"right\"][data-state~=\"hidePlayButton\"] %autoplay": "display:none;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.SlideShowTextBottom'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_helpers"
      ],
      {},
      [
        "div",
        "autoplay",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ],
      [
        "div",
        "buttonPrev",
        [
          "_mrg"
        ],
        {},
        [
          "div",
          "buttonPrevArrow",
          [],
          {}
        ]
      ],
      [
        "div",
        "counter",
        [
          "_mrg"
        ],
        {}
      ],
      [
        "div",
        "buttonNext",
        [
          "_mrg"
        ],
        {},
        [
          "div",
          "buttonNextArrow",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.SlideShowTextBottomDisplayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "txt": "BORDER_COLOR"
  },
  "paramsDefaults": {
    "txt": "color_15"
  },
  "css": {
    "%": "-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-drag:none;-ms-user-drag:none;user-drag:none;-webkit-user-modify:ready-only;-moz-user-modify:ready-only;-ms-user-modify:ready-only;user-modify:ready-only;",
    "%_helpers": "bottom:70px;height:10px;text-align:right;color:[txt];position:relative;",
    "%_helpers div": "display:inline-block;font-family:arial, helvetica, sans-serif;font-size:12px;",
    "%autoplay": "display:inline-block;width:10px;height:10px;line-height:10px;padding:5px;cursor:pointer;text-align:center;",
    "%[data-state~=\"autoplayOff\"] %autoplay > span": "display:inline-block;border:5px solid transparent;border-left:5px solid [txt];width:0;height:0;",
    "%[data-state~=\"autoplayOn\"] %autoplay > span": "display:inline-block;border-left:2px solid [txt];border-right:2px solid [txt];height:10px;width:1px;margin-right:5px;",
    "%buttonPrev,%buttonNext,%autoplay": "padding:15px;",
    "%buttonPrevArrow,%buttonNextArrow": "cursor:pointer;height:6px;width:6px;position:relative;border:solid [txt];background:transparent;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);",
    "%buttonPrevArrow": "border-width:1px 0 0 1px;",
    "%buttonNextArrow": "border-width:0 1px 1px 0;",
    "%[data-text-alignment=\"right\"] %imageItempanel": "right:0;left:130px;",
    "%[data-text-alignment=\"right\"] %_helpers": "text-align:left;",
    "%[data-text-alignment=\"right\"][data-state~=\"hidePlayButton\"] %autoplay": "display:none;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.SlideShowTextFloating'] = {
  "react": [
    [
      "div",
      null,
      [
        "_border"
      ],
      {}
    ],
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_buttons"
      ],
      {},
      [
        "div",
        "buttonPrev",
        [
          "_btn"
        ],
        {}
      ],
      [
        "div",
        "buttonNext",
        [
          "_btn"
        ],
        {}
      ]
    ],
    [
      "div",
      "counter",
      [],
      {}
    ],
    [
      "div",
      "autoplay",
      [],
      {},
      [
        "span",
        null,
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.SlideTextFloatingDisplayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "trns": "TRANSITION",
    "tdr": "URL",
    "txt": "BORDER_COLOR",
    "s": "SIZE"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "brd": "color_15",
    "brw": "0",
    "trns": "opacity 0.5s ease 0s",
    "tdr": "BASE_THEME_DIRECTORY",
    "txt": "color_11",
    "s": [
      "brw"
    ]
  },
  "paramsMutators": {
    "s": {
      "type": "increase",
      "value": 20,
      "param": "brw"
    }
  },
  "css": {
    "%": "-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-drag:none;-ms-user-drag:none;user-drag:none;-webkit-user-modify:ready-only;-moz-user-modify:ready-only;-ms-user-modify:ready-only;user-modify:ready-only;[rd]  [shd]  overflow:hidden;background:[brd];",
    "%:hover > %_buttons,%:hover > %autoplay,%:hover %_pnl": "opacity:1;",
    "%_border": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:[brw] solid [brd];pointer-events:none;background:none !important;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  overflow:hidden;",
    "%_btn": "[trns]  opacity:0.4;height:70px;background:url([tdr]arrows_white_new3.png) no-repeat;width:45px;position:absolute;top:50%;margin-top:-35px;cursor:pointer;",
    "%[data-state~=\"mobile\"] %_btn": "opacity:1;",
    "%buttonPrev": "left:20px;background-position:0 0;",
    "%buttonNext": "right:20px;background-position:100% 0;",
    "%autoplay": "[trns]  opacity:0;position:absolute;right:[s];bottom:[s];width:10px;height:10px;padding:5px;cursor:pointer;",
    "%counter": "display:none;",
    "%[data-state~=\"autoplayOff\"] %autoplay > span": "display:inline-block;border:5px solid transparent;border-left:5px solid [txt];width:0;height:0;position:relative;z-index:0;",
    "%[data-state~=\"autoplayOn\"] %autoplay > span": "display:inline-block;border-left:2px solid [txt];border-right:2px solid [txt];height:10px;width:1px;margin-right:5px;position:relative;z-index:0;",
    "%_buttons": "[trns]  opacity:0;",
    "%[data-state~=\"mobile\"] %_buttons": "opacity:1;",
    "%[data-state~=\"showTextPanel\"] %_pnl": "opacity:1;",
    "%_btn:hover": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_buttons,%[data-state~=\"touchRollOver\"] %autoplay,%[data-state~=\"touchRollOver\"] %_pnl,%[data-state~=\"touchRollOver\"] %_btn": "opacity:1;",
    "%[data-state~=\"showButtons\"] %_buttons": "opacity:1;",
    "%[data-text-alignment=\"right\"] %imageItempanel": "right:20px;left:initial;margin-right:initial;margin-left:100px;",
    "%[data-text-alignment=\"right\"] %autoplay": "left:20px;right:initial;",
    "%[data-text-alignment=\"right\"][data-state~=\"hidePlayButton\"] %autoplay": "display:none;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.SlideShowTextOverlay'] = {
  "react": [
    [
      "div",
      null,
      [
        "_border"
      ],
      {}
    ],
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_buttons"
      ],
      {},
      [
        "div",
        "buttonPrev",
        [
          "_btn"
        ],
        {}
      ],
      [
        "div",
        "buttonNext",
        [
          "_btn"
        ],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_helpers"
      ],
      {},
      [
        "div",
        "autoplay",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ],
      [
        "div",
        "counter",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.SlideTextOverlayDisplayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "trns": "TRANSITION",
    "tdr": "URL",
    "txt": "BORDER_COLOR"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "brd": "color_15",
    "brw": "0",
    "trns": "opacity 0.5s ease 0s",
    "tdr": "BASE_THEME_DIRECTORY",
    "txt": "color_11"
  },
  "css": {
    "%": "-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-drag:none;-ms-user-drag:none;user-drag:none;-webkit-user-modify:ready-only;-moz-user-modify:ready-only;-ms-user-modify:ready-only;user-modify:ready-only;[rd]  [shd]  overflow:hidden;background:[brd];",
    "%_border": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:[brw] solid [brd];pointer-events:none;background:none !important;",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  overflow:hidden;-webkit-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0);",
    "%_btn": "[trns]  opacity:0.4;height:70px;background:url([tdr]arrows_white_new3.png) no-repeat;width:45px;position:absolute;top:50%;margin-top:-35px;cursor:pointer;",
    "%[data-state~=\"mobile\"] %_btn": "opacity:1;",
    "%buttonPrev": "left:20px;background-position:0 0;",
    "%buttonNext": "right:20px;background-position:100% 0;",
    "%_helpers": "[trns]  opacity:0;position:absolute;right:[brw];bottom:[brw];padding:0 10px 10px 0;width:60px;text-align:right;",
    "%counter": "display:inline-block;color:[txt];font-family:arial, helvetica,sans-serif;font-size:12px;padding-left:5px;",
    "%autoplay": "display:inline-block;padding-left:10px;width:20px;min-height:10px;position:relative;text-align:center;",
    "%autoplay > span": "display:inline-block;position:relative;z-index:0;",
    "%[data-state~=\"autoplayOff\"] %autoplay > span": "border:5px solid transparent;border-left:5px solid [txt];width:0;height:0;",
    "%[data-state~=\"autoplayOn\"] %autoplay > span": "border-left:2px solid [txt];border-right:2px solid [txt];height:10px;width:1px;margin-right:5px;",
    "%_buttons": "[trns]  opacity:0;",
    "%[data-state~=\"mobile\"] %_buttons": "opacity:1;",
    "%:hover > %_buttons": "opacity:1;",
    "%:hover > %_helpers": "opacity:1;",
    "%:hover %_pnl": "opacity:1;",
    "%[data-state~=\"showTextPanel\"] %_pnl": "opacity:1;",
    "%_btn:hover": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_buttons": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_helpers": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_pnl": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_btn": "opacity:1;",
    "%[data-state~=\"showButtons\"] %_buttons": "opacity:1;",
    "%[data-text-alignment=\"right\"] %imageItempanel": "padding-right:10px;padding-left:100px;",
    "%[data-text-alignment=\"right\"] %_helpers": "left:0px;right:initial;text-align:left;",
    "%[data-text-alignment=\"right\"][data-state~=\"hidePlayButton\"] %autoplay": "display:none;"
  }
}
 skins['wysiwyg.viewer.skins.gallery.SlideShowTextRight'] = {
  "react": [
    [
      "div",
      "itemsContainer",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_buttons"
      ],
      {},
      [
        "div",
        "buttonPrev",
        [
          "_btn"
        ],
        {}
      ],
      [
        "div",
        "buttonNext",
        [
          "_btn"
        ],
        {}
      ]
    ],
    [
      "div",
      null,
      [
        "_helpers"
      ],
      {},
      [
        "div",
        "autoplay",
        [],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ],
      [
        "div",
        "counter",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "imageItem": {
      "skin": "wysiwyg.viewer.skins.displayers.SlideTextRightDisplayer",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "brd": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "trns": "TRANSITION",
    "tdr": "URL",
    "txt": "BORDER_COLOR",
    "a": "SIZE"
  },
  "paramsDefaults": {
    "brd": "color_11",
    "rd": "0",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "trns": "opacity 0.5s ease 0s",
    "tdr": "BASE_THEME_DIRECTORY",
    "txt": "color_15",
    "a": [
      "brw"
    ]
  },
  "paramsMutators": {
    "a": {
      "type": "increase",
      "value": 10,
      "param": "brw"
    }
  },
  "css": {
    "%": "-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-drag:none;-ms-user-drag:none;user-drag:none;-webkit-user-modify:ready-only;-moz-user-modify:ready-only;-ms-user-modify:ready-only;user-modify:ready-only;background-color:[brd];[rd]  [shd]",
    "%itemsContainer": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_btn": "[trns]  opacity:0.4;height:70px;background:url([tdr]arrows_white_new3.png) no-repeat;width:45px;position:absolute;top:50%;margin-top:-35px;cursor:pointer;",
    "%[data-state~=\"mobile\"] %_btn": "opacity:1;",
    "%buttonPrev": "left:20px;background-position:0 0;",
    "%buttonNext": "right:260px;background-position:100% 0;",
    "%[data-state~=\"mobileView\"] %buttonNext": "right:20px;",
    "%_helpers": "position:absolute;right:[a];bottom:[a];text-align:right;",
    "%counter": "display:none;",
    "%autoplay": "display:inline-block;padding-left:10px;width:20px;min-height:10px;position:relative;text-align:center;",
    "%autoplay > span": "display:inline-block;position:relative;z-index:0;",
    "%[data-state~=\"autoplayOff\"] %autoplay > span": "border:5px solid transparent;border-left:5px solid [txt];width:0;height:0;",
    "%[data-state~=\"autoplayOn\"] %autoplay > span": "border-left:2px solid [txt];border-right:2px solid [txt];height:10px;width:1px;margin-right:5px;",
    "%_buttons": "[trns]  opacity:0;",
    "%[data-state~=\"mobile\"] %_buttons": "opacity:1;",
    "%:hover > %_buttons": "opacity:1;",
    "%:hover %_pnl": "opacity:1;",
    "%[data-state~=\"showTextPanel\"] %_pnl": "opacity:1;",
    "%_btn:hover": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_buttons": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_helpers": "opacity:1;",
    "%[data-state~=\"touchRollOver\"] %_btn": "opacity:1;",
    "%[data-state~=\"showButtons\"] %_buttons": "opacity:1;",
    "%[data-state~=\"showButtons\"] %_pnl": "opacity:1;",
    "%[data-state~=\"showButtons\"] %_btn": "opacity:1;"
  }
}

return skins;

});
